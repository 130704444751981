
function resetPageForm(){
	if (!$('.app-form-page').length) {
		return false;
	}
	
	$(document).on('af_complete', function(event, response) {
		$('.app-form-page').trigger('reset');
		$('.app-form-page select').trigger('change');
	});
}

/**
 * Инит модуля
 */
function init() {
	resetPageForm();
}

module.exports = {
	init,
};
