
function selectTime(){
	if (!$('.js-select-time').length) {
		return false;
	}
	let select = $('.js-select-time');
	select.each(function(index, el) {
		if ($(this).hasClass('app-select--dark')) {
			$(this).select2({
				minimumResultsForSearch: -1,
				width: 'auto',
				theme: 'time-dark',
			});
		} else {
			$(this).select2({
				minimumResultsForSearch: -1,
				width: 'auto',
				theme: 'time',
			});
		}
	});
}

/**
 * Инит модуля
 */
function init() {
	selectTime();
}

module.exports = {
	init,
};
