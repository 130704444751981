
function phoneMask(){
	if (!$('.js-mask-phone').length) {
		return false;
	}
	
	$('.js-mask-phone').each(function(index, el) {
		$(this).inputmask({
			mask: '+7 999 999 99 99'
		});
	});
}

/**
 * Инит модуля
 */
function init() {
	phoneMask();
}

module.exports = {
	init,
};
