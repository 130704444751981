function showMenu() {
	$('.js-modal-open').on('click',function(){
		let dataModal = $(this).data('modal');
		let Modal = $('.app-modal[data-modal=' + dataModal + ']');
		
		if (!Modal.length) {
			return false;
		}

		Modal.addClass('app-modal--open');

		$('body').css({
			'overflow-y' : 'hidden',
		});
		$('html').css({
			'overflow-y' : 'hidden',
		});
		
	});
	$('.js-modal-close').on('click',function(){
		$(this).closest('.app-modal').animate({
			'right': '-110%',
			},
			300, function() {
				$(this).removeClass('app-modal--open').removeAttr("style");
		});
		$('body').css({
			'overflow-y' : 'auto',
		});
		$('html').css({
			'overflow-y' : 'auto',
		});
	});
	$(document).on('af_complete', function(event, response) {
		
	    $('.app-modal').animate({
			'right': '-110%',
			},
			300, function() {
				$(this).removeClass('app-modal--open').removeAttr("style");
		});
		$('body').css({
			'overflow-y' : 'auto',
		});
		$('html').css({
			'overflow-y' : 'auto',
		});
		$('.app-modal').trigger('reset');
		$('.app-modal select').trigger('change');
	});
}

function init() {
	showMenu();
}

export default {
	init,
}
