/*
Пример подключения модулей:
test.js: 
export default function() {}
main.js:
import test from './test';
test();
*/
import Header from './components/header';
import Select from './selects/time-select';
import MaskPhone from './inputmask/phone';
import PageFormReset from './pageForm/pageForm';
$(function() {
	Header.init();
	Select.init();
	MaskPhone.init();
	PageFormReset.init();
});